<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <div>
              <v-btn class="mr-3" color="green" dark @click="getAll()">
                atualizar
              </v-btn>
            </div>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data: () => ({
    snackbar: false,
    resultQuery: null,
    items: [],
    message: [],
    message2: [],
    options: {},
    TotalResgister: 0,
    numberOfPages: 0,
    page: 1,
    displayError2: {
      display2: false,
      message2: [],
    },

    headers: [
      { text: "Código", value: "code" },
      {
        text: "CONTEÚDO",
        align: "start",
        sortable: false,
        value: "content",
      },
      { text: "EIXO", value: "axie.title", sortable: false },
      { text: "Sub-Eixo", value: "sub_axie.title", sortable: false },
      { text: "ÂMBITO", value: "proposal_ambit.name", sortable: false },
    ],
  }),

  props: {},

  components: {},

  watch: {
    options: {
      handler() {
        this.getAll();
      },
    },

    deep: true,
  },

  created() {
    this.getAll();
  },

  methods: {
    getAll() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }
      axios
        .get(
          `conference/${this.$route.query.id}/proposal-eligible-state-ambit`,
          {
            params: {
              state_id: this.$route.query.state_id,
              page: pageNumber,
              per_page: data.per_page,
            },
          }
        )
        .then((res) => {
          this.items = res.data.data;
          this.loading = false;
          this.TotalResgister = res.data.total;
          this.numberOfPages = res.data.last_page;
        });
    },
  },
};
</script>
<style scoped>
.delegate-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right !important;
  margin: 0 20px;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.delegate-button div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.areatext {
  width: 480px;
}
</style>