<template>
  <div class="main-municipality">
    <div class="responsibility">
      <p>
        Estado <span>{{ user.state }}</span>
      </p>

      <div class="responsavel">
        <v-icon
          v-show="disabled_button"
          @click="editConference()"
          size="32"
          class="icon"
          color="icon"
          >mdi-pencil</v-icon
        >
        <p>
          Responsável: <span>{{ user.name }}</span>
        </p>
      </div>
    </div>
    <v-dialog v-model="dialogEdit" width="800">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">
              Documentos
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 3" step="3">
              Endereço
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="480px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    masked="false"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfCommission"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Data de nascimento"
                    v-model="birthDate"
                    type="date"
                    id="date"
                    placeholder="Data de Nascimento"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    :rules="emailRules"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    :rules="mandatory"
                    v-mask="['(##) ####-#####']"
                    v-model="tell"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="dataAll.getAllGender"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    :rules="mandatory"
                    v-model="genre"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="dataAll.getAllBreed"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    id="breed"
                    v-model="breed"
                    label="Raça/Cor"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="dataAll.getAllEntity"
                    item-text="name"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-text-field
                    label="RG"
                    id="tellResi"
                    :rules="mandatory"
                    v-model="rg"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    required
                  ></v-text-field>

                  <v-text-field
                    label="Orgão/Emissor"
                    :counter="4"
                    :rules="mandatory"
                    id="tellResi"
                    v-model="issuer"
                    :max="4"
                    placeholder="Ex: SSP"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form2" lazy-validation>
                  <v-textarea
                    filled
                    no-resize
                    v-model="observation"
                    solo
                    class="textareamain"
                    name="input-7-4"
                    label="Observação"
                  ></v-textarea>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                <v-btn color="primary" @click="nexTabTwo()"> continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form3" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Logradouro"
                    type="text"
                    :rules="nameLogradouro"
                    :counter="60"
                    placeholder="Logradouro"
                    v-model="publicPlace"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="CEP"
                    id="cep"
                    :rules="mandatory"
                    placeholder="CEP"
                    v-model="cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="BAIRRO"
                    v-model="district"
                    placeholder="BAIRRO"
                    :rules="namesTrick"
                    :counter="40"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-select
                    :items="dataAll.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    :rules="mandatory"
                    class="input-ajust grid-col-segund"
                    label="Municipio"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-text-field
                    label="NÚMERO"
                    v-model="numberStreet"
                    type="number"
                    id="numberStreet"
                    :counter="3"
                    min="0"
                    placeholder="Número Residência"
                    :rules="numberResidencie"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 2"> Voltar </v-btn>
                <v-btn color="primary" @click="submitFormEdit()">
                  Finalizar
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="#BD372E"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1"> Comissão </v-tab>

        <v-tab href="#tab-2"> Facilitadores </v-tab>

        <v-tab href="#tab-3"> Delegados </v-tab>
        <v-tab href="#tab-7">
          <br />
          <strong style="font-size: 11px">(Consolidadas)</strong> Diretrizes
        </v-tab>
        <v-tab href="#tab-11">
          <br />
          <strong style="font-size: 11px">(Fase Plenária)</strong> Diretrizes
        </v-tab>
        <v-tab href="#tab-8">
          <br />
          <strong style="font-size: 11px">(Eleitas)</strong> Diretrizes
        </v-tab>
        <v-tab href="#tab-4">
          <br />
          <strong style="font-size: 11px"> (Consolidadas)</strong> Propostas
        </v-tab>
        <v-tab href="#tab-12">
          <br />
          <strong style="font-size: 11px">(Fase Plenária)</strong> Propostas
        </v-tab>
        <v-tab href="#tab-9">
          <br />
          <strong style="font-size: 11px"> (Eleitas Âmbito Nacional)</strong>
          Propostas
        </v-tab>
        <v-tab href="#tab-10">
          <br />
          <strong style="font-size: 11px"> (Eleitas Âmbito Estadual)</strong>
          Propostas
        </v-tab>
        <v-tab href="#tab-5"> Anexos </v-tab>
        <v-tab href="#tab-6"> Finalização </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="tab-1">
          <tableCommission :resource="dataAll" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="2" value="tab-2">
          <tableFacilitators :resource="dataAll" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="3" value="tab-3">
          <tableDelegate :resource="dataAll" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="4" value="tab-4">
          <tableProposal :resource="dataAll" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="5" value="tab-5">
          <tableFile :resource="dataAll" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="6" value="tab-6">
          <tableNode :resource="dataAll" ref="note" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="7" value="tab-7">
          <tableDiretrizes :resource="dataAll" :origin="'state'"/>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="8" value="tab-8">
          <tableDiretrizesEleita />
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="9" value="tab-9">
          <tableProposalNationalAmbit />
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="10" value="tab-10">
          <tableProposalStateAmbit />
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="11" value="tab-11">
          <tableDiretrizFasePlenaria/>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="12" value="tab-12">
          <tableProposalPlenaryPhase/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";
import tableDiretrizFasePlenaria from "../table-conference/table-diretrizes-fase-plenaria.vue";
import tableProposalPlenaryPhase from "../table-conference/table-proposal-plenary-phase.vue";
import tableProposalStateAmbit from "../table-conference/table-proposal-eligible-state-ambit.vue";
import tableProposalNationalAmbit from "../table-conference/table-proposal-eligible-national-ambit.vue";
import tableCommission from "../table-conference/table-commission.vue";
import tableFacilitators from "../table-conference/table-facilitators.vue";
import tableDelegate from "../table-conference/table-delegate.vue";
import tableProposal from "../table-conference/table-proposal.vue";
import tableDiretrizes from "../table-conference/table-diretrizes.vue";
import tableDiretrizesEleita from "../table-conference/table-diretrizes-eleitas.vue";
import tableFile from "../table-conference/table-anexos.vue";
import tableNode from "../table-conference/table-note.vue";

export default {
  watch: {
    tab(e) {
      if (e == "tab-6") {
        this.$refs.note.getAllSelects();
      }
    },
  },
  data() {
    return {
      tab: null,
      disabled_button: false,
      dialog: false,
      e1: 1,
      dialogEdit: false,
      snackbar: false,

      nameReponsable: null,

      //Responsáveis
      dialogLaunch: false,
      id_responsible: null,
      breastfeeding: false,
      escortActive: false,
      message2: null,

      displayError: {
        display: false,
        message: [],
      },

      displayError2: {
        display2: false,
        message2: [],
      },
      page: 1,
      totalRegister: 0,
      numberOfPages: 0,
      loading: true,
      sortBy: "date",

      // Cadastro
      name: null,
      socialName: null,
      modality: null,
      ownership: null,
      ownerships: [],
      modalitys: [],
      cpfCommission: "",
      segment: null,
      segments: [],
      birthDate: null,
      genre: null,
      breed: null,
      issuingAgency: null,
      rg: null,
      checkedDeficiency: false,
      selectDeficiency: null,
      tell: "",
      issuer: "",
      email: null,
      nameEscort: null,
      rgEscort: null,
      cpfEscort: null,
      nameSocialEscort: null,
      genderEscort: null,
      genreEscort: null,
      observation: null,
      people_conference_id: "",
      conference_id: "",
      //IDS
      id_phone: null,
      id_address: null,
      id_email: null,
      id_rg: null,
      id_cpf: null,
      institute_id: null,

      // Endereço
      publicPlace: null,
      cep: null,
      numberStreet: null,
      municipaly: null,
      district: null,

      getAllGender: [],
      getAllBreed: [],
      getAllCounty: [],
      getAllDeficiency: [],
      getAllEntity: [],

      //Estrutura
      formData: {
        people: {
          name: null,
          gender_id: null,
          modality_id: null,
          birth_date: null,
          social_name: null,
          agency_id: null,
          breed_id: null,
          conference_id: null, //Id da conferência vinculada
        },
        address: {
          cep: null,
          county_id: null,
          public_place: null,
          address_number: null,
          district: null,
        },

        phone: [],
        documents: {
          RG: {
            document_number: null,
            issuing_body: null,
          },
          CPF: {
            document_number: null,
          },
        },
        email: {
          name: null,
        },
      },

      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      resumeRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 150) || "Você excedeu o tamanho máximo.",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],

      user: {},
      dataAll: {
        getAllGender: [],
        getAllBreed: [],
        getAllCounty: [],
        getAllDeficiency: [],
        getAllEntity: [],
      },
    };
  },
  components: {
    tableProposalPlenaryPhase,
    tableDiretrizFasePlenaria,
    tableProposalStateAmbit,
    tableProposalNationalAmbit,
    tableDiretrizesEleita,
    tableCommission,
    tableFacilitators,
    tableDelegate,
    tableProposal,
    tableFile,
    tableNode,
    tableDiretrizes,
    AlertError2,
  },

  methods: {
    editConference() {
      if (
        Number(this.$store.state.profile_id) == 1 ||
        Number(this.$store.state.profile_id) == 2
      ) {
        this.dialogEdit = true;
        axios
          .get(
            `/people-conference/${Number(
              this.$route.query.state_id
            )}/responsible-by-state-id?conference_id=${Number(
              this.$route.query.id
            )}`
          )
          .then((res) => {
            //IDS
            this.conference_id = res.data.conference_id;
            this.id_responsible = res.data.responsible.id;
            this.id_phone = res.data.responsible.phone[0].id;
            this.id_email = res.data.responsible.email[0].id;
            this.id_address = res.data.responsible.address[0].id;
            this.people_conference_id = res.data.id;
            this.name = res.data.responsible.name;
            this.socialName = res.data.responsible.social_name;
            this.birthDate =
              res.data.responsible.birth_date == null
                ? ""
                : res.data.responsible.birth_date.slice(0, 10);

            this.email = res.data.responsible.email[0].name;
            this.tell =
              res.data.responsible.phone[0].ddd +
              res.data.responsible.phone[0].phone_number;
            this.genre = Number(res.data.responsible.gender_id);
            this.breed = Number(res.data.responsible.breed_id);
            this.issuingAgency = Number(res.data.responsible.agency_id);
            if (res.data.responsible.rg) {
              this.id_rg = res.data.responsible.rg[0].id;
              this.rg = res.data.responsible.rg[0].document_number;
              this.issuer = res.data.responsible.rg[0].issuing_body;
            }

            this.id_cpf = res.data.responsible.cpf[0].id;
            this.cpf = res.data.responsible.cpf[0].document_number;
            this.cpfCommission = res.data.responsible.cpf[0].document_number;

            this.observation = res.data.responsible.observation;
            this.cep = res.data.responsible.address[0].cep;
            this.publicPlace = res.data.responsible.address[0].public_place;
            this.numberStreet = res.data.responsible.address[0].address_number;
            this.district = res.data.responsible.address[0].district;
            this.municipaly = Number(res.data.responsible.address[0].county_id);
          });
      }
    },

    submitFormEdit() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;
      if (!this.$refs.form3.validate()) return;

      function removeFirstTwoDigits(phoneNumber, id) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        return [{ ddd: ddd, phone_number: phoneNumber, id }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      // First stap
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.people.birth_date = this.birthDate;
      this.formData.email.name = this.email;
      this.formData.email.id = this.id_email;
      this.formData.documents.RG.id = this.id_rg;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.phone = removeFirstTwoDigits(newPhone, this.id_phone);

      this.formData.people.gender_id = this.genre;
      this.formData.people.ownership_id = this.ownership;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.breed_id = this.breed;
      // End First stap
      this.formData.people.people_conference_id = this.people_conference_id;
      this.formData.people.conference_id = this.conference_id;

      this.formData.people.observation = this.observation;
      // Start Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;
      this.formData.address.id = this.id_address;
      // End Endereço

      axios
        .put(`people/${this.id_responsible}/responsible`, this.formData)
        .then((res) => {
          this.snackbar = true;
          this.message2 = res.data.message;
          this.$refs.form3.reset();
          this.$refs.form2.reset();
          this.$refs.form.reset();
          setTimeout(() => {
            this.e1 = 1;
            this.dialogEdit = false;
          }, 2000);
        })
        .catch((err) => {
          this.displayError2.message2 = err.data.message;
          this.displayError2.display2 = true;
        });
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },

    nexTabTwo() {
      if (!this.$refs.form2.validate()) return;
      this.e1 = 3;
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    getGender() {
      axios.get(`/gender`).then((res) => {
        this.dataAll.getAllGender = res.data;
      });
    },

    getBreed() {
      axios.get(`/breed`).then((res) => {
        this.dataAll.getAllBreed = res.data;
      });
    },

    getCounty() {
      axios.get(`/county`).then((res) => {
        this.dataAll.getAllCounty = res.data;
      });
    },

    getDeficiency() {
      axios.get(`/deficiency`).then((res) => {
        this.dataAll.getAllDeficiency = res.data;
      });
    },

    getEntity() {
      axios
        .get(
          `/people-conference/${Number(
            this.$route.query.state_id
          )}/responsible-by-state-id?conference_id=${this.$route.query.id}`
        )
        .then((res) => {
          this.user.name = res.data.responsible.name;
          this.id_responsible = res.data.responsible.id;
          this.user.state = res.data.state.name;
          this.state = res.data.state_id;
        });

      axios.get(`/agency`).then((res) => {
        this.dataAll.getAllEntity = res.data;
      });

      if (
        Number(this.$store.state.finished_release_conference) &&
        !Number(this.$store.state.profile_id) == 1
      ) {
        return this.$router.push({ path: "/dashboard" });
      }

      if (
        Number(this.$store.state.profile_id) == 1 ||
        Number(this.$store.state.profile_id == 2)
      ) {
        this.disabled_button = true;
      }
    },
  },

  mounted() {
    this.getGender(),
      this.getBreed(),
      this.getCounty(),
      this.getDeficiency(),
      this.getEntity();
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.v-tab{
  font-size: 15px !important;
}

.responsibility {
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsibility span span {
  font-weight: 500;
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal > button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.responsavel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.responsavel div {
  cursor: pointer !important;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px;
  gap: 8px;
}

.textareamain {
  height: 100%;
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}
</style>
