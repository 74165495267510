<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template
          v-slot:item.actions="{ item }"
          v-if="$store.state.profile_id != 2 && $store.state.profile_id != 21 && $store.state.profile_id != 41"
        >
          <div style="display: flex; justify-content: start">
            <SwitchButton
              :resource="item"
              :TypeOfSwitch="'GUIDELINE_ELIGIBLE'"
            />
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </div>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <div>
              <v-btn class="mr-3" color="green" dark @click="getAll()">
                atualizar
              </v-btn>
            </div>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogEdit" max-width="780">      
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro Da Diretriz
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="400px">
                <v-form ref="form" lazy-validation class="tab-2-dialog">
                  <v-row>
                    <v-col cols="12" md="6" v-if="$route.query.state_id">
                      <v-text-field
                        label="Código"
                        :rules="codeRules"
                        :counter="6"
                        v-model="code"
                        placeholder="Código*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-select
                        :items="eixos"
                        item-text="title"
                        :rules="mandatory"
                        item-value="id"
                        id="Eixo"
                        v-model="eixo"
                        label="Eixo"
                        required
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-textarea
                        no-resize
                        full-width
                        label="Conteúdo da Diretriz"
                        :rules="nameRules"
                        :counter="1200"
                        v-model="content"
                        class="areatext"
                        placeholder="Conteúdo da Diretriz*"
                        outlined
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> CADASTRAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import SwitchButton from "../switch/SwitchProposalOrGuideline-comp.vue";

export default {
  data: () => ({
    snackbar: false,
    resultQuery: null,
    items: [],
    eixos: [],
    message: [],
    message2: [],
    options: {},
    TotalResgister: 0,
    numberOfPages: 0,
    dialogEdit: false,
    id_proposal: "",
    code: "",
    content: "",
    eixo: "",
    page: 1,
    e1: 1,
    displayError2: {
      display2: false,
      message2: [],
    },

    codeRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 6) || "Você excedeu o tamanho máximo!",
    ],

    mandatory: [(v) => !!v || "Campo obrigatório!"],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 1200) || "Você excedeu o tamanho máximo.",
    ],

    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      { text: "CÓDIGO", value: "code" },
      {
        text: "CONTEÚDO DA DIRETRIZ",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "EIXO", value: "axie.title" },
    ],
  }),

  props: {},

  components: {
    SwitchButton
  },

  watch: {
    options: {
      handler() {
        this.getAll();
      },
    },

    deep: true,
  },

  created() {
    this.getAll();
  },

  methods: {
    editItem(item) {
      this.loadSelects();
      this.dialogEdit = true;
      this.id_proposal = item.id;
      this.code = item.code;
      this.content = item.name;
      this.eixo = Number(item.axie_id);
    },
    loadSelects() {
      axios.get(`/conference/${this.$route.query.id}/axie`).then((response) => {
        this.eixos = response.data;
      });
    },
    getAll() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }
      axios
        .get(`conference/${this.$route.query.id}/guideline-plenary`, {
          params: {
            state_id: this.$route.query.state_id,
            page: pageNumber,
            per_page: data.per_page,
          },
        })
        .then((res) => {
          this.items = res.data.data;
          this.loading = false;
          this.TotalResgister = res.data.total;
          this.numberOfPages = res.data.last_page;
        });
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      let complete = {
        name: this.content,
        axie_id: this.eixo,
        code: this.code,
      };

      if (this.$route.query.state_id) {
        complete.state_id = this.$route.query.state_id;
      }

      this.formData = complete;

      axios
        .put(`guideline/${this.id_proposal}`, this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form.reset();
          this.getAll();
          setTimeout(() => {
            this.dialogEdit = false;
          }, 2000);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao lançar diretrizes",
            text: err.data.details,
          });
        });
    },
  },
};
</script>
<style scoped>
.delegate-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right !important;
  margin: 0 20px;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.delegate-button div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.areatext {
  width: 480px;
}
</style>